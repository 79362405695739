<template>
	<article-wrapper
			:progressColor="`#0096D6`">

			<template v-slot:hero>
				<hero class="letters__hero">
					<h1>Susan<br>Cosgrove</h1>
					<p>A Message from the Chief Financial Officer</p>
				</hero>
			</template>

			<template v-slot:body>
				<div class="letters__body" >
					<p><strong>Dear Stakeholder:</strong></p>

					<p>Despite the onset of a global pandemic that caused unprecedented disruption and dislocation across financial markets worldwide, DTCC delivered strong performance in 2020, achieving our highest-ever revenue due to heightened processing volumes associated with significant market volatility. This continues the positive trend of consistent year-over-year financial performance that we have achieved for nearly a decade now. At the same time, we reinforced the enormous value that DTCC delivers to the industry by continuing to provide the same trusted risk management and operational resilience that have been cornerstones of the firm since our founding nearly a half-century ago. </p>

					<p>Net income was $212 million before payment of our preferred dividend, slightly lower than our 2019 performance of $218 million, as we have delivered on our strategy of lowering client fees while making critical investments in the infrastructure to support risk management, technology upgrades and product innovation. </p>

					<p>Total revenues increased to $1.887 billion after a payment of a rebate to participants of The Depository Trust Company (DTC). This is an increase of $121 million, or 6.8%, over 2019 performance, and enabled our second rebate in as many years, reflecting the benefits the industry derives from a user-owned and governed utility. In 2020, we were in a position to provide this rebate to members of one of our Systemically Important Financial Market Utility (SIFMU) businesses as significant market volatility drove revenue in excess of our annual plan.</p>

					<img src="~@/assets/letters/susanImg1@2x.jpg" width="477" />

					<h2 class="small">Earnings performance</h2>

					<p>Total EBITDA (earnings before interest, tax, depreciation and amortization) was $459 million in 2020, an increase of 16% from the prior year. EBITDA margin increased to 24% in 2020, up 2 points from 2019 levels. This reflects our efforts to manage our overall expense base despite significant increases associated with investments in modernizing our systems, enhancing risk management processes, leading technology innovation, as well as increased costs associated with liquidity resources for management of member default risk due to market dislocations associated with the pandemic.</p>

					<img src="~@/assets/letters/susanImg2@2x.jpg" width="477" />

					<p>While we are always rigorous about expense management, the unprecedented nature of the crisis led to total expenses for 2020 increasing $74 million above prior year due in large part to operational and net interest cost of default liquidity to support National Securities Clearing Corporation (NSCC) and DTC. When adjusted for these dynamics, expenses were $37 million greater than 2019, reflecting increased costs to fund our strategic technology initiatives, modernization of our market platforms and our response to the pandemic, which included incremental costs for enhanced sanitation, office reconfiguration, remote technologies, transportation and supplemental compensation for on-site staff directly supporting the SIFMU businesses. We are proud as an organization to have put the safety and well-being of our employees first while continuing to provide a resilient technical and operational infrastructure to support the financial markets during a period of extreme uncertainty and significant volatility.<br><br></p>

					<h2 class="small">Business segment revenue performance</h2>

					<p>Our SIFMU and Solutions segments both performed well in 2020, with revenue growing 9.6% in our SIFMU businesses year-over-year before payment of the participant rebate. Solutions revenue was 1% lower than 2019 due primarily to fee reductions in our Repository & Derivatives Services (RDS) and Institutional Trade Processing (ITP) businesses. Excluding those fee reductions, the Solutions business would have grown 2% in 2020.</p>

					<img src="~@/assets/letters/susanImg3@2x.jpg" width="483" />

					<h2 class="small">Summary balance sheet</h2>

					<p>We continue to maintain strong equity resources with minimal financial leverage to strengthen our resilience in any market environment, providing continuity of services and stability for our clients and the markets we serve. The increase in Participants’ Assets and Liabilities predominantly representing the Clearing and Participant Funds of the SIFMUs was driven by market volatility caused by the pandemic. Excluding the long-term debt for default liquidity purposes, which is leverage-neutral given proceeds are held in the form of cash, we reduced other corporate debt to a de-minimis amount of ~$4 million. This further strengthened our balance sheet, enhanced our capital structure and improved credit profile.</p>

					<img src="~@/assets/letters/susanImg4@2x.jpg" width="520" />

					<h2 class="small">Default liquidity resources</h2>

					<p>In our role as a trusted post-trade market infrastructure provider, we maintain extensive financial resources in our SIFMU businesses to facilitate daily clearing and settlement. In 2020, we executed on our strategy to further diversify our default liquidity resources through the inaugural issuance of $3.8 billion of senior unsecured term debt in 3- and 5-year maturities to complement our liquidity resource portfolio. This enabled us to access a new market to raise default liquidity to complement the clearing fund, a committed line of credit and our commercial paper program, among other sources of financing, in the event a clearing member fails to meet its obligations and we are required to cease to act. Raising funds through a debt issuance helps increase the resilience of the clearinghouse and mitigates systemic risk.</p>

					<img src="~@/assets/letters/susanImg5@2x.jpg" width="548" />

					<p>
						<img src="~@/assets/letters/susansig@2x.jpg" width="420" style="margin: 0;" /><br>
						<strong>Susan Cosgrove</strong><br>
						<em>Chief Financial Officer</em>
					</p>
				</div>
			</template>

			<template v-slot:footer>
				<article-next class="letters__next" :image="'letters/bgMike@2x.jpg'" :link="{params: {letter: 'mike'}}" target-id="next_article" v-hero-click="`#next_article`">
					<template v-slot:label>OUR LEADERSHIP</template>
					<template v-slot:title>Susan Cosgrove</template>
				</article-next>
			</template>

		</article-wrapper>
</template>

<script>
import ArticleNext from '@/components/ArticleNext'
import ArticleWrapper from '@/components/ArticleWrapper'
import Hero from '@/components/Hero'

export default {
	name: 'Susan',
	components: {
		ArticleNext,
		ArticleWrapper,
		Hero,
	},
}
</script>

<style scoped lang="scss">

.letters {

	&__hero {
		background-image: url('~@/assets/letters/bgSusan@2x.jpg');
	}

	img {
		@include gutter('margin-top', 4);
		@include gutter('margin-bottom', 4);
		max-width: 100%;
		height: auto;
		display: block;
		margin-left: auto;
		margin-right: auto;
	}
}

</style>
